import React from 'react'

import { graphql } from 'gatsby'

import { withStyles } from '@material-ui/core'

import QuoteLayout from '../components/layout/quote/QuoteLayout'
import QuoteForm from '../components/forms/quote/QuoteForm'

const styles = theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  pageContainer: {
    paddingTop: '100px',
    paddingBottom: '80px'
  }
})

const quote = (props) => {
  const pathname = props.location.pathname
  const {
    apiUrl,
    gReCPATCHAKey
  } = props.data.site.siteMetadata
  const classes = props.classes

  // console.log(`Using config apiUrl: ${apiUrl}`)
  // console.log(`Using config gReCAPTCHAKey: ${gReCPATCHAKey}`)

  return (
    <QuoteLayout activeSection='devis'
      title='Obtenir mes tarifs'
      description='Formulaire de devis Phileass pour tout type de demande : prospects, clients, candidats, etc.'
      pathname={pathname}>
      <div className={classes.layout}>
        <div className={classes.pageContainer}>
          <QuoteForm apiUrl={apiUrl} gReCPATCHAKey={gReCPATCHAKey} />
        </div>
      </div>
    </QuoteLayout>
  )
}

export default withStyles(styles)(quote)

export const query = graphql`
  query {
    site {
      siteMetadata {
        apiUrl
        gReCPATCHAKey
      }
    }
  }
`
